import React, { useState, useEffect } from 'react';
import config from '../config';

const Profiles = ({ onDragStart }) => {
  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [name, setName] = useState('');
  const [publicName, setPublicName] = useState('');
  const [description, setDescription] = useState('');
  const [fullBackground, setFullBackground] = useState('');
  const [published, setPublished] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [voiceId, setVoiceId] = useState('');

  useEffect(() => {
    fetchProfiles();

    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, []);

  const fetchProfiles = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/profiles`);
      const data = await response.json();
      setProfiles(data.map(profile => ({...profile, timestamp: Date.now()})));
    } catch (error) {
      setError('Error fetching profiles: ' + error.message);
    }
  };

  const handleSelectProfile = (profile) => {
    setSelectedProfile({...profile, timestamp: Date.now()});
    setName(profile.name);
    setPublicName(profile.public_name || '');
    setDescription(profile.description);
    setFullBackground(profile.full_background || '');
    setPublished(profile.published || false);
    setImage(null);
    setImagePreview(null);
    setError('');
    setSuccess('');
    setVoiceId(profile.voice_id || '');
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append('name', name);
    formData.append('public_name', publicName);
    formData.append('description', description);
    formData.append('full_background', fullBackground);
    formData.append('published', published);
    if (image) {
      formData.append('image', image);
    }
    formData.append('voice_id', voiceId);

    try {
      const url = selectedProfile
        ? `${config.apiBaseUrl}/api/profiles/${selectedProfile.id}`
        : `${config.apiBaseUrl}/api/profiles`;
      const method = selectedProfile ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method: method,
        body: formData,
      });

      if (!response.ok) throw new Error('Failed to save profile');

      setSuccess('Profile saved successfully! Refreshing...');

      setTimeout(async () => {
        await fetchProfiles();
        setSelectedProfile(null);
        setName('');
        setPublicName('');
        setDescription('');
        setFullBackground('');
        setPublished(false);
        setImage(null);
        setImagePreview(null);
        setError('');
        setIsLoading(false);
        setVoiceId('');
      }, 300);
    } catch (error) {
      setError('Error saving profile: ' + error.message);
      setIsLoading(false);
    }
  };

  const handleGenerateImage = async () => {
    if (!selectedProfile) {
      setError('Please select a profile first');
      return;
    }

    setIsGeneratingImage(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch(`${config.apiBaseUrl}/api/profiles/${selectedProfile.id}/generate-image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fullBackground: description }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate image');
      }

      setSuccess('Image generated successfully! Refreshing...');

      setTimeout(async () => {
        await fetchProfiles();
        if (selectedProfile) {
          handleSelectProfile({...selectedProfile, id: selectedProfile.id});
        }
        setIsGeneratingImage(false);
      }, 300);
    } catch (error) {
      setError('Error generating image: ' + error.message);
      setIsGeneratingImage(false);
    }
  };

  const handleDeleteProfile = async (profileId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this profile?");
    if (confirmDelete) {
      try {
        const response = await fetch(`${config.apiBaseUrl}/api/profiles/${profileId}`, {
          method: 'DELETE',
        });

        if (!response.ok) throw new Error('Failed to delete profile');

        setSuccess('Profile deleted successfully! Refreshing...');

        setTimeout(async () => {
          await fetchProfiles();
          if (selectedProfile && selectedProfile.id === profileId) {
            setSelectedProfile(null);
            setName('');
            setPublicName('');
            setDescription('');
            setFullBackground('');
            setPublished(false);
            setImage(null);
            setImagePreview(null);
            setVoiceId('');
          }
          setError('');
        }, 300);
      } catch (error) {
        setError('Error deleting profile: ' + error.message);
      }
    }
  };

  const handleDragStart = (e, profile) => {
    e.dataTransfer.setData('application/json', JSON.stringify({
      id: profile.id,
      name: profile.name,
      public_name: profile.public_name,
      description: profile.description,
      full_background: profile.full_background,
      published: profile.published
    }));
    if (onDragStart) {
      onDragStart(profile);
    }
  };

  return (
    <div className="profiles-container">
      <div className="profiles-list">
        <h2>Profiles</h2>
        {profiles.map((profile) => (
          <div
            key={profile.id}
            className={`profile-card ${selectedProfile && selectedProfile.id === profile.id ? 'selected' : ''}`}
          >
            <div 
              className="profile-content"
              onClick={() => handleSelectProfile(profile)}
              draggable
              onDragStart={(e) => handleDragStart(e, profile)}
            >
              <img
                src={`${config.apiBaseUrl}/api/profiles/${profile.id}/image?t=${profile.timestamp}`}
                alt={profile.name}
                onError={(e) => { e.target.onerror = null; e.target.src = '/default-profile.png'; }}
              />
              <div className="profile-info">
                <h3>{profile.name}</h3>
                <p>Public Name: {profile.public_name || 'N/A'}</p>
                <p>{profile.description.length > 100 
                  ? `${profile.description.substring(0, 100)}...` 
                  : profile.description}
                </p>
                <p>Published: {profile.published ? 'Yes' : 'No'}</p>
              </div>
            </div>
            <button 
              className="delete-button" 
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteProfile(profile.id);
              }}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
      <div className="profile-editor">
        <h2>{selectedProfile ? 'Edit Profile' : 'Create New Profile'}</h2>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="publicName">Public Name:</label>
            <input
              type="text"
              id="publicName"
              value={publicName}
              onChange={(e) => setPublicName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Short Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="fullBackground">Full Background:</label>
            <textarea
              id="fullBackground"
              value={fullBackground}
              onChange={(e) => setFullBackground(e.target.value)}
              className="full-background"
            />
          </div>
          <div className="form-group">
            <label htmlFor="published">Published:</label>
            <input
              type="checkbox"
              id="published"
              checked={published}
              onChange={(e) => setPublished(e.target.checked)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="image">Profile Image:</label>
            <input
              type="file"
              id="image"
              onChange={handleImageChange}
              accept="image/*"
            />
          </div>
          <div className="form-group">
            <label htmlFor="voiceId">Voice ID:</label>
            <input
              type="text"
              id="voiceId"
              value={voiceId}
              onChange={(e) => setVoiceId(e.target.value)}
            />
          </div>
          <div className="image-preview">
            {imagePreview ? (
              <img src={imagePreview} alt="Image preview" className="current-profile-image" />
            ) : selectedProfile ? (
              <img
                src={`${config.apiBaseUrl}/api/profiles/${selectedProfile.id}/image?t=${selectedProfile.timestamp}`}
                alt="Current Profile"
                className="current-profile-image"
                onError={(e) => { e.target.onerror = null; e.target.src = '/default-profile.png'; }}
              />
            ) : null}
          </div>
          <button type="submit" className="submit-button" disabled={isLoading}>
            {isLoading ? 'Saving...' : (selectedProfile ? 'Update Profile' : 'Create Profile')}
          </button>
          {selectedProfile && (
            <button 
              type="button" 
              className="generate-image-button" 
              onClick={handleGenerateImage}
              disabled={isGeneratingImage}
            >
              {isGeneratingImage ? 'Generating...' : 'Generate Image'}
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Profiles;
  